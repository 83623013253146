import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { copyToClipboard } from '../functions/Functions'
import AnalyzerBar from "../modules/AnalyzerBar";
import AnalyzerScan from "../modules/AnalyzerScan";

const SCAnalyzer: React.FC = () => {
  const { scaddress, network } = useParams();
  const [ mainnet, setMainnet ] = useState<boolean>();

  const changeNetwork = (netw:boolean) => {
    const currentUrl = window.location.href.split("/")
    let newUrl;
    if(currentUrl.length < 5)
      newUrl = currentUrl.slice(0, 5).join("/") + "/AS..";
    else
      newUrl = currentUrl.slice(0, 5).join("/");
    newUrl = `${newUrl}/${netw?"mainnet":"buildnet"}`;
    window.history.pushState({}, '', newUrl);
    setMainnet(netw);
  }

  useEffect(() => {
    if(scaddress)
    {
      if(!network || network === "mainnet")
        changeNetwork(true);
      else
        changeNetwork(false);
    }
  }, [scaddress, network]);

 return (
    <div className="mt-3">
      <div className="text-2xl truncate">SC Analyzer <span className="text-sm"><button className={mainnet || mainnet === undefined?"font-bold underline text-blue-500":" text-blue-500 hover:underline"} onClick={() => changeNetwork(true)}>mainnet</button> | <button className={!mainnet && mainnet !== undefined?"font-bold underline text-blue-500":" text-blue-500 hover:underline"} onClick={() => changeNetwork(false)}>buildnet</button></span></div>
      <AnalyzerBar mainnet={mainnet !== undefined ? mainnet : (!network || network === "mainnet"?true:false)} />
      {scaddress && scaddress.length > 5 ? 
        <AnalyzerScan mainnet={mainnet !== undefined ? mainnet : (!network || network === "mainnet"?true:false)} />
      :
      <div>
        <div className="text-xl truncate">Example</div>
        <div className="text-sm truncate mt-2">
          <div>Smart contract</div>
          <span className="font-bold size-20" onClick={() => copyToClipboard("AS12qzyNBDnwqq2vYwvUMHzrtMkVp6nQGJJ3TETVKF5HCd4yymzJP")}>AS12qzyNBDnwqq2vYwvUMHzrtMkVp6nQGJJ3TETVKF5HCd4yymzJP</span>
        </div>
      </div>
      }
    </div>
  );
};

export default SCAnalyzer;

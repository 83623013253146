import React, { useEffect, useState } from "react";
import { OperationData } from "../interfaces/Interfaces"
import { formatTimeAgo } from '../functions/Functions'
import { useParams } from "react-router-dom";
import { useMyContext } from "../context/GlobalContext"
import { useNavigation } from '../hooks/useNavigation';
import SearchBar from "../modules/SearchBar";

const TokenExplorerOperation: React.FC = () => {
  const { operation } = useParams();
  const { goTo } = useNavigation();
  const { explorer } = useMyContext();
  const [operationData, setOperationData] = useState<OperationData | null>();
  const [error, setError] = useState<string | null>(null);
  
  const fetchOperation = async () => {
    try {
      const responseOperation = await fetch('https://api.massa.ga/operation/' + operation);
      if (!responseOperation.ok) {
        throw new Error('Error retrieving data..');
      }
      const resultOperation: OperationData = await responseOperation.json();
      setOperationData(resultOperation);
      setError(null);
    } catch (error) {
      setError('Error retrieving data or incorrect operation id..');
      setOperationData(null);
    }
  };

  useEffect(() => {
    fetchOperation();
  }, [operation]);

  return (
  <div>
    <SearchBar />
    <div className="mt-3 mb-5">
      <div className="text-2xl truncate">Operation <span className="font-bold">{operation}</span></div>
      <div className="rounded-md text-sm text-blue-950 bg-blue-100 px-2 font-bold py-5 uppercase">
        <div>Details</div>
      </div>
      {error &&
        <div className="text-left text-red-500 p-2">{error}</div>
      }
      {operationData &&
        <div className="grid grid-cols-4 border border-blue-100 rounded-md mt-1 px-2">
          <div>Operation:</div>
          <div className="col-span-3 truncate">{operationData["ID_OP"]}</div>
          <div>Status:</div>
          <div className="col-span-3">
            {operationData["STATUS"] === 1 ?
              <span className="rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-500">Success</span>
            :
              (operationData["STATUS"] === 0 ?
                <span><span className="rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-600">Failed</span> <button className="text-sm italic text-blue-500 hover:underline" onClick={() => goTo("/events-viewer/operation/" + operationData["ID_OP"])}>show events</button></span>
              : 
                <span className="rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-600">Pending</span>
              )
            }
          </div>
          <div>Age:</div>
          <div className="col-span-3">{formatTimeAgo(operationData["TIMESTAMP"])} ago</div>
          <div>In Block:</div>
          <div className="col-span-3 truncate"><button className="text-blue-500 hover:underline" onClick={() => goTo(explorer["block"] + operationData["ID_BLOCK"])}>{operationData["ID_BLOCK"]}</button></div>
          <div className="pt-2">From:</div>
          <div className="col-span-3 pt-2 truncate"><button className="text-blue-500 hover:underline" onClick={() => goTo(explorer["address"] + operationData["FROM_ADDR"])}>{operationData["FROM_ADDR"]}</button></div>
          <div>To:</div>
          <div className="col-span-3 truncate"><button className="text-blue-500 hover:underline" onClick={() => goTo(explorer["address"] + operationData["TO_ADDR"])}>{operationData["TO_ADDR"]}</button></div>
          <div className="pt-2">Transfered Token:</div>
          <div className="col-span-3 pt-2 truncate content-end">
            {!operationData.SYMBOL ?
              <div>NFT #{operationData.AMOUNT}</div>
            :
              <div>{(parseInt(operationData["AMOUNT"])/Math.pow(10, operationData["DECIMALS"])).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: operationData["DECIMALS"] })} <button className="text-blue-500 hover:underline" onClick={() => goTo(explorer["token"] + operationData["CONTRACT"])}>{operationData["SYMBOL"]} <span className="text-sm italic">({operationData["NAME"]})</span></button></div>
            }
          </div>
          {operationData["CONTRACT"] !== "0" && <div>Transfered Coins:</div>}
          {operationData["CONTRACT"] !== "0" && <div className="col-span-3 content-end">{operationData["TRANSFERED_COINS"]} MAS</div>}
          <div>Fees:</div>
          <div className="col-span-3">{operationData["FEES"]} MAS</div>
        </div>
      }
    </div>
  </div>
  )
};

export default TokenExplorerOperation;

export const formatTimeAgo = (timestamp: number) => {
    const now = Date.now();
    const elapsed = now - timestamp;

    const seconds = Math.floor((elapsed / 1000) % 60);
    const minutes = Math.floor((elapsed / 1000 / 60) % 60);
    const hours = Math.floor((elapsed / (1000 * 60 * 60)) % 24);
    const days = Math.floor(elapsed / (1000 * 60 * 60 * 24));

    let formatted = '';
    if (days > 0) formatted += `${days}d `;
    if (hours > 0 || days > 0) formatted += `${hours}h `;
    if (minutes > 0 || hours > 0 || days > 0) formatted += `${minutes}m `;
    formatted += `${seconds}s`;

    return formatted.trim();
};

export const cuteNumber = (price: number, decimals: number) => {
    const formattedValue = price.toFixed(decimals);
    const [integerPart, decimalPart] = formattedValue.split(".");
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
}

export const setCookie = (cookieName: string, value: any, expirationInMin: number = 1) => {
    const now = Date.now();
    const expirationInMilliseconds = expirationInMin*60000;
    const expirationTime = now + expirationInMilliseconds;
  
    document.cookie = `${cookieName}=${value}; expires=${new Date(expirationTime).toUTCString()}`;
};

export const getCookie = (cookieName: string): any =>  {
    const cookieArr = document.cookie.split('; ');
  
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split('=');
  
      if (cookiePair[0] === cookieName) {
        return cookiePair[1];
      }
    }
    
    return 0;
};

export const copyToClipboard = async (textToCopy: string): Promise<boolean> => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    try {
      await navigator.clipboard.writeText(textToCopy);
      return true;
    } catch (err) {
      console.error('Error copying to clipboard: ', err);
      return false;
    }
  } else {
    console.warn('Clipboard API is not available in your browser.');
    return false;
  }
};